.OfferingGroup {
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-color: #4f5354;
    width: 50vw;
    height: 75px;
    margin: 10px;
    box-shadow: 1px 1px 1px lightgray;
    border-radius: 6px;
    cursor: pointer;
}
.OfferingGroup:hover {
    box-shadow: 5px 5px 10px lightgray;
}

.OfferingGroupTitle {
    border-right: 1px solid #e9ecef;
    width: 25vw;
    font-size: larger;
    font-weight: bolder;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.OfferingGroupDescription {
    border-style: solid;
    border-color: #e9ecef;
    background-color: #ffffff;
    width: 100%;
    font-size: large;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.OfferingChevron {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}