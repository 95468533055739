.slide-image {
    left:100vw;
}

.slide-image-visible{
    position: absolute;
    left: 100vw;
    width: 50vw;
    margin-right: 1vw;
    visibility: visible;
    opacity: 0;
    -webkit-animation: slideon 0.8s forwards;
    -webkit-animation-delay: 0s;
    animation: slideon 0.8s forwards;
    animation-delay: 0s;
}

.slide-image-hidden {
    position: absolute;
    opacity: 100%;
    left: 0;
    width: 50vw;
    margin-right: 1vw;
    -webkit-animation: slideoff 0.8s forwards;
    -webkit-animation-delay: 0s;
    animation: slideoff 0.8s forwards;
    animation-delay: 0s;
}

@-webkit-keyframes slideon {
    100% { left: 0; }
}

@keyframes slideon {
    100% { left: 0; opacity: 100%}
}

@-webkit-keyframes slideoff{
    100% { left: -100vw; opacity: 0}
}

@keyframes slideoff {
    100% { left: -100vw; }
    100% { left: -100vw; opacity: 0}
}