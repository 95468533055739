.App {
  background-color: #f6fbff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


#navbar {
  background-color: #167D79;
  height: 75px;
  top: 0px;
  width: 100%;
  position: absolute;
  z-index: 999;
  color: #d8ecfa;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.navButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 25px;
}

.navButton {
  color: #d8ecfa;
  text-decoration: none; /* no underline */
  padding: 12px;
  display: inline-block;
  position: relative;
}

.navButton.active  {
  color: #E5F9FF;
  border-bottom: #E5F9FF;
  border-bottom-style: solid;
  border-width: 2px;
}

.navButton:hover {
  color: #E5F9FF;
}

.navButton:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #E5F9FF;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.navButton:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.Row {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-basis: 0;
  flex-wrap: wrap-reverse;
}

.Column {
  position: relative;
  width: 40vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 500px;
}

.Attributes {
  font-size: 1.8vw;
  color: #4f5354;
}

h1.section-heading {
  color: #4f5354;
  font-size: 4vw;
  line-height: 3.8vw;
  margin-bottom: 14px;
}

h2.section-heading {
  color: #4f5354;
  font-size: 2.5vw;
  line-height: 3.8vw;
  margin-bottom: 14px;
}

.button-row {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.space {
  flex-grow: 1;
  min-width: 10px;
  max-width: 50px;
  min-height: 10px;
}

#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active+.fp-tooltip {
  color: #167D79;
}


.featureList li:hover {
  font-weight: bold;
  cursor: pointer;
}

.active-feature {
  font-weight: bold;
}

/*.slide-image {*/
/*  max-height: 70vh;*/
/*  background-repeat: no-repeat;*/
/*  aspect-ratio: 16/9;*/
/*  background-size: contain;*/
/*}*/

@media screen and (min-width: 801px) {
  .featureList {
    margin-top: 20px;
    font-size: medium;
  }

  div.prose {
    font-size: medium;
  }
}

@media screen and (max-width: 800px) {
  .featureList {
    margin-top: 2px;
    font-size: x-small;
  }
  div.prose {
    font-size: x-small;
  }
}

.list-group-item {
  color: #4f5354;
  background-color: #d8ecfa55;
  cursor: pointer;
  font-size: 1vw;
}

.list-group-item.active {
  color: #E5F9FF;
  background-color: #167D79;
  border-color: #d8ecfa55;
}

.offering-card {
  height: 100%;
  width: 24.5vw;
  min-width: 200px;
}


.footer {
  color: #d8ecfa;
  background-color: #414b43;
  position: absolute;
  width: 100vw;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-top: 2px inset #e9ecef;
  font-size: small;
}

.footer.hide {
  bottom: 0;
  opacity: 100%;
  -webkit-animation: slideDown 0.2s forwards;
  -webkit-animation-delay: 0s;
  animation: slideDown 0s forwards;
  animation-delay: 0s;
}

.footer.show {
  bottom: -90px;
  opacity: 0;
  -webkit-animation: slideUp 0.7s forwards;
  -webkit-animation-delay: 0.15s;
  animation: slideUp 0.7s forwards;
  animation-delay: 0.15s;
}


.card-text {
  font-size: 10pt;
}

@-webkit-keyframes slideUp {
  100% { bottom: 0; }
}

@keyframes slideUp {
  100% { bottom: 0; opacity: 100%}
}

@-webkit-keyframes slideDown{
  100% { bottom: -90px; opacity: 0}
}

@keyframes slideDown {
  100% { bottom: -90vw; opacity: 0}
}